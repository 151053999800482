var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('paged-table-view',{attrs:{"search-function":_vm.fetchData,"use-cache":!_vm.userId},scopedSlots:_vm._u([{key:"condition",fn:function(){return [_c('el-form-item',[_c('el-input',{attrs:{"clearable":"","placeholder":"请输入工单名称"},model:{value:(_vm.queryForm.workOrderDisplayName),callback:function ($$v) {_vm.$set(_vm.queryForm, "workOrderDisplayName", $$v)},expression:"queryForm.workOrderDisplayName"}})],1),_c('el-form-item',[_c('el-select',{attrs:{"placeholder":"状态","clearable":""},model:{value:(_vm.queryForm.status),callback:function ($$v) {_vm.$set(_vm.queryForm, "status", $$v)},expression:"queryForm.status"}},[_c('el-option',{attrs:{"value":"InExecution","label":"进行中"}}),_c('el-option',{attrs:{"value":"InAcceptance","label":"验收中"}}),_c('el-option',{attrs:{"value":"Accepted","label":"已验收"}}),_c('el-option',{attrs:{"value":"Settled","label":"已结算"}})],1)],1)]},proxy:true},{key:"table",fn:function(){return [_c('el-table-column',{attrs:{"label":"工单名称"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workOrder.displayName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"工单编号","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workOrder.workOrderNo)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"工单周期(天)","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.workOrderCycle)+"天 ")]}}])}),_c('el-table-column',{attrs:{"label":"认领人员"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.user.realName)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"反馈次数","prop":"feedbackTimes"}}),_c('el-table-column',{attrs:{"label":"累计工作量","prop":"totalOutputCount","width":"100"}}),_c('el-table-column',{attrs:{"label":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('work-order-participator-status-tag',{attrs:{"status":row.status}})]}}])}),_c('el-table-column',{attrs:{"label":"操作","width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleViewDetail(scope.row)}}},[_vm._v(" 反馈记录 ")])]}}])})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }